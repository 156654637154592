<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
    class="py-2 px-3"
  >
    <v-container
      fluid
      class="ma-1 pa-0"
    >
      <captable-dashboard-core-footer />
    </v-container>
  </v-footer>
</template>

<script>
  import CaptableDashboardCoreFooter from './CaptableFooterInfo'
  export default {
    name: 'DashboardCoreFooter',

    components: {
      CaptableDashboardCoreFooter,
    },

    data: () => ({
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    small
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
  .v-footer
    border-top: none !important
  .theme--light.v-footer
    background-color: transparent !important
</style>
