<template>
  <v-row
    no-gutters
  >
    <v-col
      v-for="(link, i) in links"
      :key="i"
      class="text-center mb-sm-0 mb-5"
      cols="auto"
    >
      <a
        :href="link.href"
        class="trsText--text text-body-1 mr-0 text-decoration-none"
        :class="{
          'primary--text': !link.none
        }"
        rel="noopener"
        :target="link.href === '#' ? '_self' : '_blank'"
        v-text="link.text"
      />
    </v-col>

    <v-spacer class="hidden-sm-and-down" />

    <v-col
      cols="12"
      md="auto"
    >
      <div class="trsText--text text-body-1 font-weight-light pt-6 pt-md-0 text-center">
        &copy; {{ new Date().getFullYear() }} All Rights Reserved
      </div>
    </v-col>
  </v-row>
</template>
<script>
  import { BUILD_NUMBER } from '@/constants'
  export default {
    name: 'CaptableDashboardCoreFooter',

    data: () => ({
    }),

    computed: {
      branding () {
        return this.$store.getters['account/branding']
      },
      publicBranding () {
        return this.$store.getters['publicBranding/s3Data']
      },
      links () {
        const info = this.$getPublicBrandingFooterInfo(this.publicBranding)

        const defaultText = 'Powered by Trellis Software Inc.'
        const drOptions = this.publicBranding.drOptions || {}
        const finalPoweredBy = (drOptions.poweredByText || defaultText) + ' ' + BUILD_NUMBER

        return [
          {
            text: finalPoweredBy,
            href: '#',
            none: true,
          },
          {
            text: 'Contact Us',
            href: `mailto:${info.contact}`,
          },
          {
            text: 'Terms and Conditions',
            href: info.terms,
          },
          {
            text: 'Privacy Policy',
            href: info.privacy_policy,
          },
        ]
      },
    },
  }
</script>
